import React, { useState, useEffect } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import ReactPaginate from "react-paginate";
import logo from "../assets/logo.png";

const Reports = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [error, setError] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 30;

  const handleSearch = async () => {
    try {
      if (!startDate || !endDate) {
        setError("Please select a start and end date.");
        return;
      }

      const payload = { startDate, endDate };
      const apiUrl =
        "https://backendmd.marsbpo.org/api/attendance/getAttendanceDetailsForAllEmployees";
      const token = localStorage.getItem("token");

      if (!token) {
        console.error(
          "Token not found in localStorage. Redirect to login or handle accordingly."
        );
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(apiUrl, payload, { headers });
      setAttendanceDetails(response.data);
      if (response.data.length === 0) {
        setError("No records found.");
        return;
      }

      setError("");
      setIsDataFetched(true);
    } catch (error) {
      setError("Error fetching data. Please try again.");
    }
  };

  const generatePDF = async () => {
    setIsLoading(true);
    try {
      const content = generatePDFContent();

      const element = document.createElement("div");
      element.innerHTML = content;

      await html2pdf(element, {
        margin: 1,
        filename: "attendance_report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDFContent = () => {
    const today = new Date().toLocaleDateString();
    let content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Attendance Report</title>
        <style>
          body {
            font-family: sans-serif;
          }
  
          .header {
            text-align: center;
            margin-bottom: 30px;
          }
  
          .logo {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
          }
  
          .date {
            font-weight: bold;
            margin-bottom: 10px;
          }
  
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 0 auto;
          }
  
          th, td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: left;
          }
  
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${logo}" alt="Logo" class="logo">
          <p class="date">Date: ${today}</p>
          <h1>Attendance Report</h1>
        </div>
    `;
  
    if (attendanceDetails.length > 0) {
      content += `
        <table>
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Total Absents</th>
              <th>Total Leaves</th>
              <th>Total WFH</th>
              <th>Total Lates</th>
              <th>Total Hours</th>
            </tr>
          </thead>
          <tbody>
      `;
  
      attendanceDetails.forEach((attendance) => {
        content += `
          <tr>
            <td>${attendance.name}</td>
            <td>${attendance.totalAbsents}</td>
            <td>${attendance.totalLeaves}</td>
            <td>${attendance.totalWFH}</td>
            <td>${attendance.totalLates}</td>
            <td>${attendance.totalHours || "N/A"}</td>
          </tr>
        `;
      });
  
      content += `
          </tbody>
        </table>
      `;
    } else {
      content += "<p>No attendance data available.</p>";
    }
  
    content += `
      </body>
      </html>
    `;
  
    return content;
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="lg:w-3/4 md:w-3/4 mx-auto p-4 mt-12">
      <div className="bg-white border-t-4 border-blue-950 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div>
                <label className="mr-2 font-semibold text-gray-700">Start Date:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="mr-2 font-semibold text-gray-700">End Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleSearch}
                className="bg-blue-950 text-white rounded px-6 py-2 font-semibold hover:bg-blue-800 transition duration-300"
              >
                Search
              </button>
              {isDataFetched && !isLoading && (
                <button
                  onClick={generatePDF}
                  className="bg-green-500 text-white rounded px-6 py-2 ml-4 font-semibold hover:bg-green-400 transition duration-300"
                >
                  Generate PDF Report
                </button>
              )}
              {isLoading && <div className="loader mt-2"></div>}
            </div>
          </div>

          {error && (
            <div className="mt-4 text-red-500">
              <p>{error}</p>
            </div>
          )}

          {isDataFetched && (
            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-4">Attendance Details:</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Employee Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Absents
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Leaves
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total WFH
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Lates
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Hours
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {attendanceDetails
                      .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                      .map((attendance, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">{attendance.name}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{attendance.totalAbsents}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{attendance.totalLeaves}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{attendance.totalWFH}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{attendance.totalLates}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{attendance.totalHours}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={Math.ceil(attendanceDetails.length / itemsPerPage)}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination flex justify-center space-x-2 mt-4"}
                  previousLinkClassName={"pagination__link px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"}
                  nextLinkClassName={"pagination__link px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"}
                  disabledClassName={"pagination__link--disabled opacity-50"}
                  activeClassName={"pagination__link--active bg-blue-700 text-white"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
