import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeCountChart from "./EmployeeCountChart";
import AttendanceChart from "./AttendanceChart";
import MainCards from "./MainCards";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const Home = () => {
  const [currentDate, setCurrentDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDate");
    return storedDate ? new Date(storedDate) : new Date();
  });

  const handleChangeDate = (e) => {
    const newDate = new Date(e.target.value);
    setCurrentDate(newDate);
    localStorage.setItem("selectedDate", formatDate(newDate));
  };

  const handleSwitchToToday = () => {
    localStorage.removeItem("selectedDate");
    setCurrentDate(new Date());
  };


  return (
    <div>
      <ToastContainer />
      <main className="p-6 sm:p-10 space-y-6">
        <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between">
          
          <div className="flex justify-between items-center mr-6">
            <div>
              <h1 className="text-4xl end-0 font-semibold mb-2">Mars Digital</h1>
            </div>
            <input
              type="date"
              value={formatDate(currentDate)}
              onChange={handleChangeDate}
              className="text-2xl absolute mr-10 end-0 font-semibold mb-2"
            />
            <button onClick={handleSwitchToToday} className="text-sm font-semibold absolute mr-10 end-0 mt-12 text-blue-950">Switch to Today</button>
          </div>
        </div>
        <MainCards date={formatDate(currentDate)} />
        <div
          style={{ backgroundColor: "#F4F4F4" }}
          className="row-span- shadow-lg rounded-lg"
        >
          <AttendanceChart date={formatDate(currentDate)} />
        </div>
        <section className="grid md:grid-cols-2 xl:grid-cols-4 xl:grid-col-3 shadow-lg xl:grid-flow-col gap-6">
          <EmployeeCountChart />
          <div
            style={{ backgroundColor: "#F4F4F4" }}
            className="flex flex-col md:col-span-2 md:row-span-2 shadow-lg rounded-lg overflow-hidden hover:shadow-lg transition duration-400 ease-in-out"
          >
            <div className="px-6 py-4 font-semibold border-b border-gray-200 bg-gray-100">
              <h2 className="text-lg text-black font-bold">
                HR Management Tools
              </h2>
            </div>
            <div className="p-4 flex-grow">
              <p className="text-gray-700">
                Welcome to the HR Management section of our system. Here you can
                access various tools and features to streamline your HR
                processes and manage your workforce efficiently. From
                maintaining an employee directory to tracking attendance and
                managing leave requests, our HR management tools provide
                comprehensive solutions to meet your organizational needs.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
